<template>
  <div>
    <div v-if="isCompanionUser">
      <p
        class="is-size-7 has-text-weight-light"
        v-if="event.sold"
      >
        {{ dateRange(event) }}
      </p>
      <p
        class="is-size-7 has-text-weight-light"
        v-else
      >
        {{ getDate(event.date) }}
      </p>
    </div>
    <p
      class="title is-size-5 has-text-dark"
      v-if="event.sold"
    >
      Previously listed for sale
    </p>
    <p
      class="title is-size-5 has-text-dark"
      v-else
    >
      Currently listed for sale
    </p>
    <div v-if="isCompanionUser && hasListings">
      <div class="has-text-weight-light content">
        <p class="is-6">
          <span class="is-valigned">
            {{ getListingCountText(dateDiff(event), filteredListings.length) }}
          </span>
        </p>
      </div>
      <ToggleHeader
        v-if="hasListings && isCompanionUser"
        v-bind="{ title, visible, isButton: true }"
        @toggle="toggle"
      >
        <transition
          enter-active-class="animated faster fadeIn"
          leave-active-class="animated faster fadeOut"
          mode="out-in"
        >
          <div v-if="visible">
            <div
              v-for="(listing, index) in filteredListings"
              :key="index"
            >
              <div>
                <div class="field">
                  <p class="is-size-7 has-text-weight-light">
                    {{ getDate(listing.firstSeen) }}
                  </p>
                  <p class="title is-6 has-text-dark">
                    Listed for sale
                  </p>
                </div>
                <div class="columns">
                  <div class="column">
                    <VehicleCarousel
                      :vrm="vrm"
                      :photos="
                        listing.isOrg || !$experian ? listing.images : []
                      "
                    />
                  </div>
                  <div class="column is-6">
                    <div class="columns is-mobile is-multiline is-vcentered">
                      <div class="column has-text-weight-light">
                        <p>Listing price</p>
                      </div>
                      <div
                        class="column has-text-right has-text-weight-semibold"
                      >
                        <p>{{ formatMoney(listing.price) }}</p>
                      </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-vcentered">
                      <div class="column has-text-weight-light">
                        <p>Listed mileage</p>
                      </div>
                      <div
                        class="column has-text-right has-text-weight-semibold"
                      >
                        <p v-if="listing.mileage">
                          {{ formatNumber(listing.mileage) }}
                        </p>
                        <p
                          class="subtitle is-6 has-text-grey"
                          v-else
                        >
                          Unavailable
                        </p>
                      </div>
                    </div>
                    <div class="columns is-mobile is-multiline is-vcentered">
                      <div class="column has-text-weight-light">
                        <p>Advert text</p>
                      </div>
                      <div class="column is-narrow has-text-weight-semibold">
                        <a
                          class="has-text-info"
                          @click="openModal('companion/AdText', listing.adText)"
                          v-if="listing.adText"
                        >
                          <span>View</span>
                          <span class="icon is-valigned">
                            <i class="fal fa-search" />
                          </span>
                        </a>
                        <p
                          class="subtitle is-6 has-text-grey"
                          v-else
                        >
                          Unavailable
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                v-if="index < filteredListings.length - 1"
                class="has-background-soft"
              >
            </div>
          </div>
        </transition>
      </ToggleHeader>
      <div v-if="!isCompanionUser">
        <p class="title is-5">
          See the previous listings for this vehicle
        </p>
        <p class="subtitle is-6 has-text-weight-light">
          Upgrade to a Companion subscription today and see the previous adverts
          of when this vehicle was on sale. Get access to prices, images and
          mileages as well as the advertising copy that was that was used to
          list the vehicle.
        </p>
        <div class="has-text-right">
          <button
            class="button is-info"
            @click="openModal('companion/UpsellCompanion')"
          >
            Unlock the vehicle's past
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate, daysDifference } from '@/utils/date.utils.ts'
import { formatMoney, formatNumber } from 'accounting'

export default {
  name: 'TimelineListing',
  props: {
    event: {
      type: Object,
      required: true
    },
    vrm: {
      type: String,
      required: true
    }
  },
  data: () => ({
    listings: null,
    expanded: false,
    visible: false
  }),
  components: {
    ToggleHeader: () => import('core/components/ToggleHeader'),
    VehicleCarousel: () =>
      import('core/modules/vehicle-carousel/components/VehicleCarousel')
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser', 'isInsurer']),
    hasListings() {
      return this.filteredListings && this.filteredListings.length > 0
    },
    filteredListings() {
      return this.event.listings || []
    },
    productStore() {
      switch (this.$route.name) {
        case 'vehicle-profile':
          return 'vpp'
        case 'autocheck':
          return 'autocheck'
        default:
          return 'companion'
      }
    },
    title: () => 'Individual listings'
  },
  methods: {
    getListingCountText(difference, count) {
      const days = difference === 1 ? 'day' : 'days'
      const times =
        count === 1 ? 'once' : count === 2 ? 'twice' : `${count} times`
      const start =
        difference === 0 ? 'This ' : `Over ${difference} ${days} this `
      return `${start} ${
        this.$store.getters[this.productStore + '/make']
      } was listed ${times}`
    },
    dateRange(event) {
      const { date, lastSeen } = event
      return date === lastSeen
        ? this.getDate(date)
        : `${this.getDate(date)} — ${this.getDate(lastSeen)}`
    },
    dateDiff(event) {
      const { date, lastSeen } = event
      return daysDifference(lastSeen, date)
    },
    getDate(value) {
      return formatDate(value, 'do MMMM yyyy')
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatMoney(value) {
      return formatMoney(value, '£', 0)
    },
    openModal(modal, data = null) {
      if (modal === 'companion/UpsellCompanion') {
        this.$mxp.track('companion_upsell_unlock_vehicles_past')
      }
      this.$modal.open(modal, data)
    },
    toggle() {
      this.visible = !this.visible
    }
  }
}
</script>

<style lang="sass" scoped>
.card-header-icon
  &.is-static
    cursor: unset
</style>
